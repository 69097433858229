import React, { Component } from "react"
import { Link } from "gatsby"
import plancvlogo from "../../../static/icons/plancv-bold.svg"

class Header extends Component {
  state = {}
  render() {
    return (
      <div className="header-container-top-v1">
        <div className="header-container-v1">
          <div className="header-left-cont-v1">
            <div>
              <Link className="logo-v1" to="/" alt=" ">
                <img src={plancvlogo} alt="" height="45px" width="45px" />
              </Link>
            </div>
            <div>
              <a
                className="header-left-btn-v1"
                href="https://youtu.be/tWkMH-uHy5o"
                alt="plancv"
                target="_blank"
              >
                Quick tour
              </a>
            </div>
            <Link style={{ textDecoration: "none" }} to="/pricing" alt=" ">
              <div className="header-left-btn-v1">Pricing</div>
            </Link>
            <div className="header-left-btn-v1">
              <a
                style={{ textDecoration: "none", color: "#65686d" }}
                //className="header-left-btn-v1"
                alt="help url"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.tidio.com/talk/gjmmvnzhgnmqrwtshbt2ys0zzgfesexd"
              >
                Help
              </a>
            </div>
          </div>
          <a
            href="https://app.plancv.com/signup"
            style={{ textDecoration: "none" }}
            className="header-middle-btn"
          >
            <div>Sign Up</div>
          </a>
          <div className="header-right-cont-v1">
            <a
              style={{ textDecoration: "none" }}
              href="https://app.plancv.com/login"
              alt=" "
            >
              <div className="header-signin-v1">Sign In</div>
            </a>
            <a
              style={{ textDecoration: "none" }}
              href="https://app.plancv.com/signup"
              alt=" "
            >
              <div className="header-signup-v1">Get Started</div>
            </a>
          </div>
          <div className="header-burger-v1" onClick={openNav}>
            &#9776;
          </div>
          {/* large collapse for barger */}
          <div id="topCollapse-v1" className="topCollapse-v1">
            <div className="collapse-top-close-v1">
              <label
                // href='javascript:void(0)'
                className="closeCollapse-v1"
                onClick={closeNav}
                alt="button"
              >
                &times;
              </label>
            </div>
            <div className="topCollapse-content-v1">
              <a
                href="https://app.plancv.com/login"
                style={{ textDecoration: "none", color: "#65686d" }}
              >
                <div className="topCollapse-item-v1 logout-collapse-v1">
                  Sign In
                </div>
              </a>
              <a
                href="https://app.plancv.com/signup"
                style={{ textDecoration: "none" }}
              >
                <div className="topCollapse-item-v1 logout-collapse-v1 signup-btn-barger-v1">
                  Sign Up
                </div>
              </a>
              <br />
              <div>
                <a
                  className="topCollapse-item-v1"
                  href="https://youtu.be/tWkMH-uHy5o"
                  target="_blank"
                >
                  Quick tour
                </a>
              </div>
              <Link style={{ textDecoration: "none" }} to="/pricing" alt=" ">
                <div className="topCollapse-item-v1">Pricing</div>
              </Link>
              <div>
                <a
                  // style={{ textDecoration: "none", color: "#65686d" }}
                  className="topCollapse-item-v1"
                  alt="help url"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.tidio.com/talk/gjmmvnzhgnmqrwtshbt2ys0zzgfesexd"
                >
                  Help
                </a>
              </div>

              <div className="topCollapse-item-v1">
                <Link
                  to="/terms"
                  alt="terms of usage"
                  className="link-navmenu-v1"
                  target="__blank"
                  rel="this is terms page"
                >
                  Terms of Use
                </Link>
              </div>
              <div className="topCollapse-item-v1">
                <Link
                  to="/privacy"
                  alt="privacy and policy"
                  className="link-navmenu-v1"
                  target="__blank"
                  rel="this is privacy and policy page"
                >
                  Privacy policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header

// open and close the topnav collapse for barger
function openNav(size) {
  document.getElementById("topCollapse-v1").style.height = "100%"
}
function closeNav() {
  document.getElementById("topCollapse-v1").style.height = "0%"
}
