import React, { Component } from "react"
import { Link } from "gatsby"

class Footer extends Component {
  state = {}
  render() {
    return (
      <div className="footer-cover-v1">
        <div className="footer-container-v1">
          <Link style={{ textDecoration: "none" }} to="/" alt=" ">
            <div className="footer-menu-item-v1">Home</div>
          </Link>
          <Link to="/terms" style={{ textDecoration: "none" }} alt=" ">
            <div className="footer-menu-item-v1">Terms of Use</div>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/privacy" alt=" ">
            <div className="footer-menu-item-v1">Privacy Policy</div>
          </Link>
          <div className="footer-menu-item-v1">
            <a
              style={{ textDecoration: "none" }}
              className="footer-menu-item-v1"
              alt="help url"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tidio.com/talk/gjmmvnzhgnmqrwtshbt2ys0zzgfesexd"
            >
              Help
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
