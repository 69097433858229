import React, { Component } from "react"
import Header from "./components/header"
import Footer from "./components/footer"
import { FcUnlock, FcDonate } from "react-icons/fc"
import { BsTrophy } from "react-icons/bs"
import Saving from "../../static/images/saving.svg"
import {
  RiAmazonFill,
  RiPaypalFill,
  RiNetflixFill,
  RiGoogleFill,
  RiMicrosoftFill,
  RiAppleFill,
} from "react-icons/ri"
import {
  BsCollection,
  BsFiles,
  BsFunnel,
  BsChatQuote,
  BsDroplet,
  BsLayoutWtf,
  BsCheck,
} from "react-icons/bs"

class Pricing extends Component {
  state = {}
  render() {
    return (
      <>
        <Header />
        <div className="pricing-cover-v1">
          <div className="pricing-cont1-v1">
            <div className="pricing-cont1-left-cont-v1">
              <div className="pricing-cont1-left-slagon-v1">
                Apply for a pro plan and jump steps in your career goal
              </div>
              <div className="pricing-slagon-points-cont-v1">
                <div className="pricing-slagon-point-cont-v1">
                  <div className="pricing-point-icon-cont-v1">
                    <FcUnlock size="3em" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    Unlock all <br /> Features
                  </div>
                </div>
                <div className="pricing-slagon-point-cont-v1">
                  <div className="pricing-point-icon-cont-v1">
                    <FcDonate size="3em" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    Affordable <br /> Pricing
                  </div>
                </div>
                <div className="pricing-slagon-point-cont-v1">
                  <div className="pricing-point-icon-cont-v1">
                    <BsTrophy size="2.5em" color="#ffb64d" />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    Beat <br /> Competation
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-cont1-right-img-v1">
              <img src={Saving} alt="saving" width="100%" height="auto" />
            </div>
          </div>
          {/* pricing boxes and titles */}
          <div className="pricing-cont2-v1">
            <div className="pricing-cont2-title-v1">
              The pricing that never hurt your wallet
            </div>
            <div className="pricing-boxes-cont-v1">
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">8</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Semi-Annual</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>$89.94</span>{" "}
                  $53.94 billed every 6 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 42%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1 pricing-box-popular-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">10</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Quarterly</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>
                    $44.97{" "}
                  </span>{" "}
                  $32.97 billed every 3 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 28%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">14</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Monthly</div>
                <div className="pricing-box-txt3-v1">Billed once a month</div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET PLAN
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-subtitle-v1">
            Top global companies hired plancv Resume/CV holders
          </div>
          <div className="land-brand-cont-v1" style={{ marginTop: "20px" }}>
            <div className="land-brand-item-v1">
              <RiGoogleFill color="#62686d" size="2.5em" />
            </div>
            <div className="land-brand-item-v1">
              <RiAppleFill color="#62686d" size="2.5em" />
            </div>
            <div className="land-brand-item-v1">
              <RiMicrosoftFill color="#62686d" size="2.5em" />
            </div>
            <div className="land-brand-item-v1">
              <RiAmazonFill color="#62686d" size="2.5em" />
            </div>
            <div className="land-brand-item-v1">
              <RiPaypalFill color="#62686d" size="2.5em" />
            </div>
            <div className="land-brand-item-v1">
              <RiNetflixFill color="#62686d" size="2.5em" />
            </div>
          </div>
          <div className="pricing-subtitle-v1" style={{ marginTop: "60px" }}>
            All Premium Plans include:
          </div>
          <div className="pricing-checkbox-v1">
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>3+ premium templates</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>18+ sections</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>24 fonts and color combinations</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>12 modern backgrounds</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>Unlimited Resume/CV pages</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>10 Resumes at same time</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>Unlimited rearrange</div>
            </div>
            <div className="pricing-checkbox-item-v1">
              <div className="pricing-checkbox-icon-v1">
                <BsCheck color="#62686d" size="1.1em" />
              </div>
              <div>Remove Branding and grammer checking</div>
            </div>
          </div>
          <div className="pricing-cont2-title-v1 pricing-title-secound-v1">
            Things that make our{" "}
            <span style={{ fontWeight: "400" }}>Resume Builder</span> bold
          </div>

          <div className="pricing-cont3">
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsCollection color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Eyes catching templates
              </div>
              <div className="pricing-features-box-desc-v1">
                Will structured, creative and professional templates that can
                easily draw the recruiter's attention to your Resume/CV.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsDroplet color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Customizable design
              </div>
              <div className="pricing-features-box-desc-v1">
                Trending and premium fonts, color combinations and backgrounds
                bring the unique reasonable design for your Resume/CV.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsLayoutWtf color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Limitless flexibility
              </div>
              <div className="pricing-features-box-desc-v1">
                More than 18 pre-build sections and you can add new sections by
                changing any of these sections and rearrange the position of
                sections.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsFunnel color="#62686d" size="1.7em" />
              </div>

              <div className="pricing-features-box-title-v1">ATS friendly</div>
              <div className="pricing-features-box-desc-v1">
                Most enterprise companies use Applicant Tracking System (ATS)
                systems for checking and filtering resumes based on keywords.
                Our Resume Builder is optimized for this system.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsChatQuote color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Live feedback and pre-build guide
              </div>
              <div className="pricing-features-box-desc-v1">
                Live feedback, recommendation, placeholder guides, and grammer
                check for each word that you type.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-iconbox-v1">
                <BsFiles color="#62686d" size="1.7em" />
              </div>
              <div className="pricing-features-box-title-v1">
                Multiple Resume/CV and pages
              </div>
              <div className="pricing-features-box-desc-v1">
                You can create and edit 10 Resume/CV at the same time with
                unlimited pages for each Resume/CV.
              </div>
            </div>
          </div>

          {/* pricing boxes and titles */}
          <div className="pricing-cont2-v1">
            <div className="pricing-cont2-title-v1">
              Take action with confidence in your career path
            </div>
            <div className="pricing-boxes-cont-v1">
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">8</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Semi-Annual</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>$89.94</span>{" "}
                  $53.94 billed every 6 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 42%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1 pricing-box-popular-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">10</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Quarterly</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>
                    $44.97{" "}
                  </span>{" "}
                  $32.97 billed every 3 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 28%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">14</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Monthly</div>
                <div className="pricing-box-txt3-v1">Billed once a month</div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET PLAN
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-cont2-title-v1 pricing-title-secound-v1">
            Frequently asked questions
          </div>
          <div className="pricing-cont3">
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                Are there any hidden costs?
              </div>
              <div className="pricing-features-box-desc-v1">
                There are no hidden costs. After selecting the period, the total
                price that you will be charged can be seen before introducing
                any payment details. (This excludes the charges required by the
                bank transfer.)
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                Is my payment information secure?
              </div>
              <div className="pricing-features-box-desc-v1">
                All transactions are 100% secure, with the option to pay through
                stripe.com , that have the highest level of security measures
                that are available.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                Do HRs like this format?
              </div>
              <div className="pricing-features-box-desc-v1">
                People have been hired at top global companies like Amazon,
                PayPal, Netflex, Google, with Plancv resumes. While liking a
                specific resume is always a matter of opinion, we believe our
                users’ success speaks for itself.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                What format will the documents be saved as?
              </div>
              <div className="pricing-features-box-desc-v1">
                For increased security, readability and overall visual aspect,
                all the documents are saved as PDFs - which is a format that
                looks the same on all devices.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                Can I try before I buy?
              </div>
              <div className="pricing-features-box-desc-v1">
                Most of the premium features are available by default in free
                account, you can build basic resume for yourself with no cost.
              </div>
            </div>
            <div className="pricing-features-box">
              <div className="pricing-features-box-title-v1">
                Will my information be saved after the Premium period expires?
              </div>
              <div className="pricing-features-box-desc-v1">
                All the information will be saved in your account, even when the
                Premium period expires.
              </div>
            </div>
          </div>
          {/* pricing boxes and titles */}
          <div className="pricing-cont2-v1" style={{ marginTop: "0px" }}>
            <div className="pricing-cont2-title-v1">
              Unlock a pro plan, unlock opportunities
            </div>
            <div className="pricing-boxes-cont-v1">
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">8</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Semi-Annual</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>$89.94</span>{" "}
                  $53.94 billed every 6 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 42%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1 pricing-box-popular-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">10</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Quarterly</div>
                <div className="pricing-box-txt3-v1">
                  <span style={{ textDecoration: "line-through" }}>
                    $44.97{" "}
                  </span>{" "}
                  $32.97 billed every 3 months
                </div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET AND SAVE 28%
                  </a>
                </div>
              </div>
              <div className="pricing-box-cont-v1">
                <div style={{ marginLeft: "30px" }}>
                  <div className="pricing-box-price-v1">
                    $<div className="pricing-numbold-v1">14</div>.99/mo
                  </div>
                </div>
                <div className="pricing-box-txt2-v1">Pro Monthly</div>
                <div className="pricing-box-txt3-v1">Billed once a month</div>
                <div>
                  <a
                    className="pricing-box-btn-v1"
                    href="https://app.plancv.com/signup"
                    alt="plancv"
                  >
                    GET PLAN
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="pricing-last-v1">
            <div className="pricing-last-text-v1">
              Building a Basic Resume/CV is Free!
              <br />
              <span className="pricing-last-span-v1">
                *No credit card required!
              </span>
            </div>
            <div>
              <a
                className="pricing-last-btn-v1"
                href="https://app.plancv.com/signup"
                alt="plancv"
              >
                Build My Resume Now
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

export default Pricing

//
